import { Vector3 } from '@babylonjs/core'
import React from 'react'
import { Mesh } from 'react-babylonjs'
import { rad } from '../../../components/Scene2'
import SimpleWallSectionSolid from './SimpleWallSectionSolid'

const GrableSolid = ({ length, width, roofPitch, gap, height }) => {
    return <Mesh name="grabble_solid_main" position={new Vector3(0, ( Math.tan(rad(roofPitch))* width/4) + height, 0)}>
        <Mesh name="grable_solid_1" position={new Vector3(0, 0, width/4)}
            rotation={new Vector3(rad(90 - roofPitch), rad(0), rad(0))} >
            <SimpleWallSectionSolid
                lengthArray={[length]}
                height={(width / 2) / Math.cos(rad(roofPitch))}
                gap={gap}
            ></SimpleWallSectionSolid>
        </Mesh>
        <Mesh name="grable_solid_2" position={new Vector3(0, 0, 3*width/4)}
            rotation={new Vector3(rad(-90 + roofPitch), rad(0), rad(0))}
        >
            <SimpleWallSectionSolid
                lengthArray={[length]}
                height={(width / 2) / Math.cos(rad(roofPitch))}
                gap={gap}
            ></SimpleWallSectionSolid>
        </Mesh>
    </Mesh>
}

export default GrableSolid