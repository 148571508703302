import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import FormRange from "react-bootstrap/esm/FormRange"
import { useDispatch, useSelector } from "react-redux"
import Slider from "react-smooth-range-input"
import useTool from "./TookHook"
import { setHeight, setLength, setLengthArray, setWidth, setRoofPitch, setRoofType } from "../../store/config-slice"
import FormCheckInput from "react-bootstrap/esm/FormCheckInput"
import { RoofTypes } from "../../store/constants"




const useSizeTool = (tools) => {


    const dispatch = useDispatch()

    const tool = useTool(tools)

    // const [length, setLength] = useState(10)
    // const [width, setWidth] = useState(10)

    let height = useSelector((state) => state.config.height)
    let width = useSelector((state) => state.config.width)
    let length = useSelector((state) => state.config.length)
    var lengthArray = useSelector((state) => state.config.lengthArray);
    var roofPitch = useSelector((state) => state.config.roofPitch);
    var roofType = useSelector((state) => state.config.roofType);

    const [customSize, setCustomSize] = useState(false)
    // const [roofType, setRoofType] = useState(roofPitch)
    // const [baySizes, setBaySizes] = useState([length])

    useEffect(() => {

        updateBaySizes(length);
    }, [length, customSize, width, height])


    const updateBaySizes = (length) => {
        if (!customSize) {
            var nWalls = parseInt(length / 9.0) + 1.0;
            var wallLength = length / nWalls;
            var wallArray = []
            for (let i = 0; i < nWalls; i++) {
                wallArray.push(wallLength)
            }
            lengthArray = wallArray
            dispatch(setLengthArray(lengthArray))
        }
    }



    const generateCustomLengthSlider = (lengthArray, index) => {
        let content = <div className="row" key={"length-range-" + index}>
            <label htmlFor="">{index}</label>
            <div className="col-9">
                <div className="form-group">
                    <Slider value={lengthArray[index]}
                        disabled={!customSize}
                        onChange={(e) => {
                            let wallArray = [...lengthArray]
                            wallArray[index] = e
                            dispatch(setLengthArray(wallArray))
                        }}
                        min={1} max={30} />
                </div>
            </div>
            <div className="col-3">
                <div className="form-group">
                    <input type="text" value={lengthArray[index]} readOnly className="form-control" />
                </div>
            </div>
        </div>
        return content;
    }

    const Controls = () => {
        if (tool.isOpen) {
            return <div className="tool-controls">
                <div className="controls">
                    <h5>Size</h5>
                    <hr />
                    <div className="form-group">
                        <label htmlFor="">Roof Type</label>
                        <select 
                        className="form-select" 
                        value={roofType}
                        onChange={e => dispatch(setRoofType(e.target.value))}
                        >
                            {Object.values(RoofTypes).map((roof, index) => 
                                <option key={index + "-" + roof} value={roof}>{roof}</option>
                            )}
                            
                        </select>

                    </div>

                    <div className="form-group">
                        <label htmlFor="">Roof Pitch</label>
                        <Slider value={roofPitch}
                        onChange={(e) => {
                            dispatch(setRoofPitch(Number(e)))
                        }}
                        min={0} max={45} />
                    </div>

                    <div className="row">
                        <label htmlFor="">Length(m)</label>
                        <div className="col-9">
                            <div className="form-group">
                                <Slider value={length}
                                    name="length"
                                    id="length"
                                    onChange={(e) => { dispatch(setLength(e)) }}
                                    min={1} max={60} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <input type="text"
                                    value={length}
                                    onChange={(e) => dispatch(setLength(e.target.value))}
                                    className="form-control" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <label htmlFor="">Width(m)</label>
                        <div className="col-9">
                            <div className="form-group">
                                <Slider value={width}
                                    onChange={(e) => { dispatch(setWidth(e)) }}
                                    min={1} max={30} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <input type="text" value={width}
                                    onChange={(e) => dispatch(setWidth(e.target.value))}
                                    className="form-control" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <label htmlFor="">Height(m)</label>
                        <div className="col-9">
                            <div className="form-group">
                                <Slider value={height}
                                    onChange={(e) => { dispatch(setHeight(e)) }}
                                    min={1} max={30} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <input value={height}
                                    onChange={(e) => { dispatch(setHeight(e.target.value)) }}
                                    type="text" className="form-control" />
                            </div>
                        </div>
                    </div>

                    <h5>Bay Sizes(m)</h5>
                    <hr />
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={customSize}
                            onChange={(e) => {

                                setCustomSize(e.target.checked)
                                console.log(customSize);
                            }}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Custom Bay Sizes
                        </label>
                    </div>

                    {lengthArray.map((length, index) => generateCustomLengthSlider(lengthArray, index))}
                </div>

            </div>
        }
        return null;
    }

    const displayControl = () => {
        if (tool.isOpen)
            tool.handler()
        else {
            tools.forEach(t => t.setTool(false))
            tool.handler();
        }

    }

    const Tool = () => <div className="tool-section">
        <div className="tool" data-options="size" onClick={e => { displayControl() }}>
            <svg style={{ "width": "24px", "height": "24px" }} width="24" height="24"
                viewBox="0 0 24 24">
                <g transform="translate(.298 -1027.945)">
                    <path
                        d="M16.84 1032.82a13.835 13.835 0 012.483 5.103h.122c.725 0 1.31-.584 1.31-1.31v-2.483c0-.725-.585-1.31-1.31-1.31z">
                    </path>
                    <rect ry="1.135" y="1044.661" x="8.47" height="3.98" width="14.864"></rect>
                    <circle cy="1039.545" cx="9.154" r="9.099"></circle>
                    <circle cy="1039.604" cx="9.194" stroke="#fff" strokeWidth="1.99" r="5.811"></circle>
                    <rect ry=".902" y="1038.955" x="16.413" height="8.24" width="1.804"></rect>
                    <rect ry=".902" y="1046.515" x="21.078" height="3.7" width="2.27"></rect>
                </g>
            </svg>
            <span>Size</span>
        </div>
    </div>
    return [Tool, Controls]
}

export default useSizeTool