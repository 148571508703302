import React, { } from "react"
import { useDispatch, useSelector } from "react-redux";
import { setView } from "../store/config-slice";
import useAwningsTool from "./tools/AwningsTool";
import useColorsTool from "./tools/ColorsTool";
import useExtrasTool from "./tools/ExtrasTool";
import useMezzanineTool from "./tools/MezzanineTool";
import useQuoteTool from "./tools/QuoteTool";
import useSizeTool from "./tools/SizeTool";
import useWallsTool from "./tools/WallsTool";



const Tools = () => {

    const tools = []

    const openedClass = () => {
        if (tools.some(v => v.isOpen)) return "tools tools-open";
        else return "tools"
    }


    const [AwningTool, AwningControls] = useAwningsTool(tools)
    const [SizeTool, SizeControls] = useSizeTool(tools)
    const [WallsTool, WallsControls] = useWallsTool(tools)
    const [ExtrasTool, ExtrasControls] = useExtrasTool(tools)
    const [MezzanineTool, MezzanineControls] = useMezzanineTool(tools)
    const [ColorsTool, ColorsControls] = useColorsTool(tools)
    const [QuoteTool, QuoteControls] = useQuoteTool(tools)

    const view = useSelector((state) => state.config.view)
    const dispatch = useDispatch();

    return <div className={openedClass()}>
        <div className="an-fixed">
            <div className="row">
                <div className="col">
                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox"
                            checked={view.solid}
                            onChange={ e =>  dispatch(setView({name:"solid", value: e.target.checked})) }
                        />
                        <label className="form-check-label" >
                            Normal
                        </label>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox"
                            checked={view.structure}
                            onChange={ e =>  dispatch(setView({name:"structure", value: e.target.checked})) }
                        />
                        <label className="form-check-label">
                            Structure Only
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className="tool-icons">
            <SizeTool></SizeTool>
            <AwningTool></AwningTool>
            <WallsTool></WallsTool>
            <ExtrasTool></ExtrasTool>
            <MezzanineTool></MezzanineTool>
            <ColorsTool></ColorsTool>
            <QuoteTool></QuoteTool>
        </div>
        <div className="tool-controls">
            <SizeControls></SizeControls>
            <AwningControls></AwningControls>
            <WallsControls></WallsControls>
            <ExtrasControls></ExtrasControls>
            <MezzanineControls></MezzanineControls>
            <ColorsControls></ColorsControls>
            <QuoteControls></QuoteControls>
        </div>


    </div>
}



export default Tools