import { useEffect, useState } from "react"
import useTool from "./TookHook"

const useExtrasTool = (tools) => {

    const tool = useTool(tools)

    const Controls = () => {
        if (tool.isOpen)
            return <div className="tool-controls">
            <div className="controls">
                <h5>Extras</h5>
                <hr />

                <p>Will be added when 3d is engine is developed.</p>
            </div>
        </div>
        return null;
    }

    const displayControl = () =>{
        if(tool.isOpen)
            tool.handler()
        else {
            tools.forEach(t => t.setTool(false))
            tool.handler();
        }
        
    }

    const Tool = () => <div className="tool-section">
        <div className={"tool"} data-options="size" onClick={e => {displayControl(); }}>
        <svg style={{ "width": "24px", "height": "24px" }} width="24" height="24" viewBox="0 0 24 24"><path d="M.408 2.91v18.18h23.184V2.91zm2 2h19.184v14.18H2.408z"></path><path d="M11 3.892V20.243h2V3.892z"></path></svg>
            <span>Extras</span>
        </div>
        
        
    </div>

    return [Tool, Controls];
}

export default useExtrasTool