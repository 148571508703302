import { useEffect, useState } from "react"
import useTool from "./TookHook"

const useColorsTool = (tools) => {

    const tool = useTool(tools)

    const Controls = () => {
        if (tool.isOpen)
            return <div className="tool-controls">
                <div className="controls">
                    <h5>Colours</h5>
                    <hr />

                    <div className="form-group">
                        <label htmlFor="">Colour Mode</label>
                        <select name="" id="" className="form-select">
                            <option value="">Simple</option>
                            <option value="">Simple</option>
                            <option value="">Simple</option>
                            <option value="">Simple</option>
                            <option value="">Simple</option>
                            <option value="">Simple</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Roof</label>
                        <select name="" id="" className="form-select">
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Walls</label>
                        <select name="" id="" className="form-select">
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Flashings</label>
                        <select name="" id="" className="form-select">
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Doors</label>
                        <select name="" id="" className="form-select">
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                            <option value="">Off White/Surfmist</option>
                        </select>
                    </div>

                    <h5>Sheeting </h5>
                    <div className="form-group">
                        <label htmlFor="">Doors</label>
                        <select name="" id="" className="form-select">
                            <option value="">Trimdek</option>
                            <option value="">Trimdek</option>
                            <option value="">Trimdek</option>
                            <option value="">Trimdek</option>
                            <option value="">Trimdek</option>
                            <option value="">Trimdek</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Doors</label>
                        <select name="" id="" className="form-select">
                            <option value="">Trimdek</option>
                            <option value="">Trimdek</option>
                            <option value="">SimTrimdekple</option>
                            <option value="">Trimdek</option>
                            <option value="">Trimdek</option>
                            <option value="">Trimdek</option>
                        </select>
                    </div>
                    

                </div>
            </div>

        return null;
    }

    const displayControl = () => {
        if (tool.isOpen)
            tool.handler()
        else {
            tools.forEach(t => t.setTool(false))
            tool.handler();
        }

    }

    const Tool = () => <div className="tool-section">
        <div className="tool" data-options="size" onClick={e => { displayControl(); }}>
            <svg style={{ "width": "24px", "height": "24px" }} width="24" height="24"
                viewBox="0 0 24 24">
                <path
                    d="M12.449 4.63a1.97 1.97 0 00-2.761 0l-7.743 7.747a1.973 1.973 0 000 2.764l7.743 7.747a1.97 1.97 0 002.76 0l7.743-7.747a1.973 1.973 0 000-2.764zm-1.38 1.448l7.675 7.681-7.676 7.681-7.676-7.68z">
                </path>
                <path
                    d="M8.365.548L6.951 1.962l.707.707 5.906 5.906.707.707 1.415-1.414-.707-.707-5.907-5.906zM3.693 14.821h14.813l-7.453 7.453zM22.382 20.975a1.86 1.86 0 11-3.72 0c0-1.027 1.86-4.355 1.86-4.355s1.86 3.328 1.86 4.355z">
                </path>
            </svg>
            <span>Colours</span>
        </div>


    </div>

    return [Tool, Controls];
}

export default useColorsTool