import { Vector3 } from "@babylonjs/core";
import React from "react";
import { Mesh } from "react-babylonjs";
import SimpleWallSection from "./SimpleWallSectionStructure";

const LeftAwningStructure = ({ leftAwning, length, offset, lengthArray, gap }) => {
    let theta = Math.atan2(leftAwning.length, leftAwning.roofPitch);
    let hypotenouse = leftAwning.length / Math.sin(theta)
    if (leftAwning.visible)
        return <Mesh name="leftAwning_1" position={new Vector3(-length/2, 0, offset/2-leftAwning.length)}>
            <SimpleWallSection
                lengthArray={lengthArray}
                position={new Vector3(0, 0, 0)}
                height={leftAwning.height}
                gap={gap}
            ></SimpleWallSection>
                <Mesh name="skillion_right_1" position={new Vector3(0, leftAwning.height, 0)}>
                    <SimpleWallSection
                        lengthArray={[length]}
                        height={hypotenouse}
                        rotation={new Vector3(theta, 0, 0)}
                        gap={gap}
                    ></SimpleWallSection>
                </Mesh>
        </Mesh>
    else return <React.Fragment></React.Fragment>;
}

export default LeftAwningStructure