// import { useEffect, useState } from "react"
import useTool from "./TookHook"

const useQuoteTool = (tools) => {

    const tool = useTool(tools)

    const Controls = () => {
        if (tool.isOpen)
            return <div className="tool-controls">
                <div className="controls">
                    <h5>Request a Quote</h5>
                    <hr />

                    <div className="form-group">
                        <label htmlFor="">First Name*</label>
                        <input type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Last Name</label>
                        <input type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Email*</label>
                        <input type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Phone Number*</label>
                        <input type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Build Address*</label>
                        <input type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Suburb*</label>
                        <input type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Post Code*</label>
                        <input type="text" className="form-control" />
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Vermin proof
                        </label>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Would you like us to quote</label>
                        <select name="" id="" className="form-select">
                            <option value="">Supply and build</option>
                            <option value="">Supply only</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Comment</label>
                        <textarea className="form-control" ></textarea>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">How did you hear about us?</label>
                        <select name="" id="" className="form-select">
                            <option value="" >Select</option>
                            <option value="">Google</option>
                            <option value="">Bing</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <div className="d-grid gap-2">
                            <button className="btn btn-primary" type="button">Submit</button>
                        </div>
                    </div>

                </div>
            </div>


        return null;
    }

    const displayControl = () => {
        if (tool.isOpen)
            tool.handler()
        else {
            tools.forEach(t => t.setTool(false))
            tool.handler();
        }

    }

    const Tool = () => <div className="tool-section">
        <div className="tool" data-options="size" onClick={e => { displayControl(); }}>
            <svg className="SVGInline-svg icon-svg" style={{ "width": "24px", "height": "24px" }} width="24" height="24"
                viewBox="0 0 24 24">
                <path
                    d="M4.734.874a1 1 0 00-1 1v20.252a1 1 0 001 1h14.532a1 1 0 001-1V1.874a1 1 0 00-1-1zm1 2h12.532v18.252H5.734z">
                </path>
                <path
                    d="M7.557 16.894a.963.963 0 00-.963.963v.18a.963.963 0 00.963.962h8.886a.963.963 0 00.963-.963v-.18a.963.963 0 00-.963-.962zM7.557 12.894a.963.963 0 00-.963.963v.18a.963.963 0 00.963.962h8.886a.963.963 0 00.963-.963v-.18a.963.963 0 00-.963-.962zM7.557 8.894a.963.963 0 00-.963.963v.18a.963.963 0 00.963.962h8.886a.963.963 0 00.963-.963v-.18a.963.963 0 00-.963-.962zM7.5 4.894a.907.907 0 00-.906.906v.293a.907.907 0 00.906.906h4.873a.907.907 0 00.906-.906V5.8a.907.907 0 00-.906-.906z">
                </path>
            </svg>
            <span>Qoute</span>
        </div>


    </div>

    return [Tool, Controls];
}

export default useQuoteTool