import { Vector3 } from "@babylonjs/core"
import React from "react"
import { Mesh } from "react-babylonjs"
import { rad } from "../../components/Scene2"
import SimpleWallSection from "./SimpleWallSectionStructure"

const GrableStructure = ({ length, width, roofPitch, gap, height }) => {
    return <React.Fragment>
        <Mesh name="grable_1" position={new Vector3(0, height, 0)}
        rotation={new Vector3(rad(90 - roofPitch), rad(0), rad(0))}
        >
            <SimpleWallSection
                lengthArray={[length]}
                height={(width / 2) / Math.cos(rad(roofPitch))}
                
                gap={gap}
            ></SimpleWallSection>
        </Mesh>
        <Mesh name="grable_2" position={new Vector3(0, height, width)}
        rotation={new Vector3(rad(-90 + roofPitch), rad(0), rad(0))}
        >
            <SimpleWallSection
                lengthArray={[length]}
                height={(width / 2) / Math.cos(rad(roofPitch))}
                
                gap={gap}
            ></SimpleWallSection>
        </Mesh>
    </React.Fragment>
}

export default GrableStructure