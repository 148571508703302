import { Vector3 } from '@babylonjs/core'
import React, { useEffect, useState } from 'react'
import { Mesh } from 'react-babylonjs'
import { rad } from '../../components/Scene2'
import LeftAwningStructure from './LeftAwningStructure'
import MezzanineSectionStructure from './MezzanineSectionStructure'
import RightAwningStructure from './RightAwningStrucuture'
import RoofStructure from './RoofStructure'
import SimpleWallSectionStructure from './SimpleWallSectionStructure'




function Structure({ totalLength, width, height, lengthArray, wallsArray, mezzaninesArray, backWall, mezzanineHeight, gap, roofType, leftAwning, rightAwning, roofPitch }) {

    function getWalls(lengthArray, wallsArray, backWall) {
        let infos = []
        let offset = 0
        if (backWall)
            infos.push(0)
        for (let i = 0; i < lengthArray.length; i++) {
            offset += lengthArray[i]
            if (wallsArray[i])
                infos.push(offset)
        }

        return infos;
    }

    function getMezzanins(lengthArray,mezzaninesArray, width) {
        let walls = [];
        let wallObjects = []
        let xoffset = 0;
        let k=0;
        for(let i of lengthArray){
            let newPostion = new Vector3(0, 0, 0)
            newPostion.x += xoffset+i/2;
            let wall = {
                key : xoffset,
                position: newPostion,
                height:width,
                width:i,
            };
            xoffset += i;
            if(mezzaninesArray[k])
            wallObjects.push(wall)
            k++;
        }
        xoffset-= lengthArray[lengthArray.length-1]
        for(let wallProps of wallObjects){
            let wall = <plane
            name="rer" 
            key={wallProps.key}
            width={wallProps.width} 
            height={wallProps.height+10} 
            position={wallProps.position}
            >
                <standardMaterial name="eref" backFaceCulling={false}/>
            </plane>
            walls.push(wall)
        }
        return walls
    }

    const [wallsOffsets, setWallsOffsets] = useState([]);
    const [mezzanineInfos, setMezzanineInfos] = useState([]);

    useEffect(() => {
        let infos = getWalls(lengthArray, wallsArray, backWall)
        setWallsOffsets(infos)
    }, [lengthArray, wallsArray, backWall]);


    useEffect(() => {
        let infos = getMezzanins(lengthArray,mezzaninesArray, height,width)
        setMezzanineInfos(infos)
    }, [lengthArray,mezzaninesArray, width]);

    return (
        <React.Fragment>
            <Mesh position={new Vector3(-totalLength / 2, 0, -width / 2)} name="main_structure">
                <SimpleWallSectionStructure
                    lengthArray={lengthArray}
                    position={new Vector3(0.0, 0.0, 0.0)}
                    height={height}
                    gap={gap}
                ></SimpleWallSectionStructure>

                <SimpleWallSectionStructure
                    lengthArray={lengthArray}
                    position={new Vector3(0.0, 0.0, width / 2)}
                    height={height}
                    gap={gap}
                ></SimpleWallSectionStructure>
                <mesh name="mag"
                position={new Vector3(0, mezzanineHeight, width/2)}
                
                rotation={new Vector3(rad(90),0,0)}
                >
                    <MezzanineSectionStructure
                        lengthArray={lengthArray}
                        mezzaninesArray={mezzaninesArray}
                        position={new Vector3(0.0, 0.0, 0)}
                        height={width}
                        gap={gap}
                        width={2}
                    >
                    </MezzanineSectionStructure>

                    {/* {mezzanineInfos.map((info, index)=>{
                        return <plane
                        name={"rer"+index} 
                        key={info.key}
                        width={info.width} 
                        height={info.height} 
                        position={info.position}
                        >
                            <standardMaterial name="eref" backFaceCulling={false}/>
                        </plane>
                    })} */}
                </mesh>
                

                {wallsOffsets.map((off, index) => {

                    return <Mesh key={"walls-2912" + index} name={"frontwall" + index} position={new Vector3(off, 0, 0)}
                        rotation={new Vector3(rad(0), rad(-90), rad(0))}>
                        <SimpleWallSectionStructure
                            lengthArray={[width]}
                            height={height}
                            gap={gap}
                        ></SimpleWallSectionStructure>
                    </Mesh>
                })}

                <RoofStructure roofType={roofType} width={width} height={height} totalLength={totalLength} gap={gap} roofPitch={roofPitch}></RoofStructure>
            </Mesh>
            <LeftAwningStructure lengthArray={lengthArray} gap={gap} leftAwning={leftAwning} length={totalLength} offset={-width}></LeftAwningStructure>
            <RightAwningStructure lengthArray={lengthArray} gap={gap} rightAwning={rightAwning} length={totalLength} offset={width}></RightAwningStructure>
        </React.Fragment>
    )
}

export default Structure