import { Mesh, Matrix, Vector3, Color4 } from "@babylonjs/core";
import { useEffect, useRef } from "react";
import { Plane } from "react-babylonjs";

const LARGURA = 50;

const WallSolid = ({
  height,
  width,
  name,
  position
}) => {
  const wallRef = useRef();

  useEffect(() => {
    if (wallRef.current === null) {
      return;
    }
  }, [wallRef]);

  return (
    // <box
    //     name={`wall-${name}`}
    //     ref={wallRef}
    //     width={1.0}
    //     height={1.0}
    //     depth={1.0}
    //     scaling={
    //       new Vector3(
    //         height ,
    //         width ,
    //         height 
    //       )
    //     }
    //   ></box>

    <plane
        name={`wall-${name}`}
        ref={wallRef}
        width={1.0}
        height={1.0}
        depth={1.0}
        scaling={
          new Vector3(
            width ,
            height,
            1.0 
          )
        }
        sideOrientation = {Mesh.DOUBLESIDE}
        position={new Vector3(position.x + width/2, position.y, position.z)}
      >
        <standardMaterial name="solidwall_texture" backFaceCulling={false}></standardMaterial>
      </plane>
  )
};

export default WallSolid;
