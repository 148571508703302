



import React from 'react'
import { RoofTypes } from '../../../store/constants'
import GrableSolid from './GrableSolid';
import SkillionLeftSolid from './SkillionLeftSolid';
import SkillionRightSolid from './SkillionRightSolid';








const RoofSolid= ({roofType, width, height, totalLength, gap, roofPitch}) => {
    if (roofType === RoofTypes.gable) {
        return <GrableSolid
            height={height}
            length={totalLength}
            width={width}
            gap={gap}
            roofPitch={roofPitch}
        />
    }
    else if (roofType === RoofTypes.skillion_right) {
        return <SkillionRightSolid
            height={height}
            length={totalLength}
            width={width}
            gap={gap}
            roofPitch={roofPitch}
        />
    }
    else if (roofType === RoofTypes.skillion_left) {
        return <SkillionLeftSolid
            height={height}
            length={totalLength}
            width={width}
            gap={gap}
            roofPitch={roofPitch}
        />
    }
    return <React.Fragment></React.Fragment>;
}

export default RoofSolid