import { Vector3 } from "@babylonjs/core";
import React from "react";
import { Mesh } from "react-babylonjs";
import SimpleWallSection from "./SimpleWallSectionStructure";

const RightAwningStructure = ({ rightAwning, length, offset, lengthArray, gap }) => {
    let theta = Math.atan2(rightAwning.length, rightAwning.roofPitch);
    let hypotenouse = rightAwning.length / Math.sin(theta)
    if (rightAwning.visible)
        return <Mesh name="righttAwning" position={new Vector3(-length/2, 0, offset/2+rightAwning.length)}>
            <SimpleWallSection
                lengthArray={lengthArray}
                position={new Vector3(0, 0, 0)}
                height={rightAwning.height}
                gap={gap}
            ></SimpleWallSection>
                <Mesh name="rightAwining_2" position={new Vector3(0, rightAwning.height, 0)}>
                    <SimpleWallSection
                        lengthArray={[length]}
                        height={hypotenouse}
                        rotation={new Vector3(-theta, 0, 0)}
                        gap={gap}
                    ></SimpleWallSection>
                </Mesh>
        </Mesh>
    else return <React.Fragment></React.Fragment>;
}

export default RightAwningStructure