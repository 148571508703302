import { Vector3 } from "@babylonjs/core";
import React from "react";
import { Mesh } from "react-babylonjs";
import SimpleWallSection from "./SimpleWallSectionStructure";

export const SkillionLeftStructure = ({ length, width, roofPitch, gap, height }) => {
    let theta = Math.atan2(width, roofPitch);
    let hypotenouse = width / Math.sin(theta)
    return <React.Fragment>
        <Mesh name="skillion_left_1" position={new Vector3(0, height, 0)}
        rotation={new Vector3(0, 0, 0)}>
            <SimpleWallSection
                lengthArray={[length]}
                height={roofPitch}
                
                gap={gap}
            ></SimpleWallSection>
        </Mesh>
        <Mesh name="skillion_left_2" position={new Vector3(0, height, width)}
        rotation={new Vector3(-theta, 0, 0)}
        >
            <SimpleWallSection
                lengthArray={[length]}
                height={hypotenouse}
                gap={gap}
            ></SimpleWallSection>
        </Mesh>
    </React.Fragment>
}

export default SkillionLeftStructure