import { Vector3 } from '@babylonjs/core'
import React, {  } from 'react'
import { Mesh } from 'react-babylonjs'
import WallSolid from './WallSolid'

const SimpleWallSectionSolid = ({ lengthArray, height, gap, position = new Vector3(0, 0.0, 0), rotation = new Vector3(0, 0, 0) }) => {

    const generateSideWalls = () => {
        let walls = [];
        let wallObjects = []
        let xoffset = 0;
        for(let i of lengthArray){
            let newPostion = new Vector3(position.x, position.y, position.z)
            newPostion.x += xoffset;
            let wall = {
                key : xoffset,
                position: newPostion,
                height:height,
                width:i,
                gap:gap,
            };
            xoffset += i;
            wallObjects.push(wall)
        }
        xoffset-= lengthArray[lengthArray.length-1]
        for(let wallProps of wallObjects){
            let wall = <WallSolid {...wallProps} ></WallSolid>
            walls.push(wall)
        }
        return walls
    }


    return (
        // <WallSolid height={boxHeight} width={height} name="wall12">
        //     {generateSideWalls()}
        // </WallSolid>
        <Mesh name="SimpleWallSectionSolid" rotation={rotation} position={position} >
        {generateSideWalls()}
    </Mesh>
    )
}

export default SimpleWallSectionSolid