import { Vector3 } from '@babylonjs/core';
import React from 'react'
import { Mesh } from 'react-babylonjs';
import Wall2 from './Wall2';

function MezzanineSectionStructure({lengthArray,mezzaninesArray, height, gap, position=new Vector3(0, 0.0, 0), rotation=new Vector3(0,0,0)}) {


function Mag({height, width, position, key}){

    return <plane
    name="rer" 
    key={key}
    width={width} 
    height={height} 
    position={position}
    >
        <standardMaterial name="eref" backFaceCulling={false}/>
    </plane>
}
const generateSideWalls = (
    lengthArray,mezzaninesArray, height, gap, position, rotation
) => {
    let walls = [];
    let wallObjects = []
    let xoffset = 0;
    let k=0;
    for(let i of lengthArray){
        let newPostion = new Vector3(position.x, position.y, position.z)
        newPostion.x += xoffset+i/2;
        let wall = {
            key : xoffset,
            position: newPostion,
            height:height,
            width:i,
            gap:gap,
            showWall:true,
            direction:0
        };
        xoffset += i;
        if(mezzaninesArray[k])
        wallObjects.push(wall)
        k++;
    }
    xoffset-= lengthArray[lengthArray.length-1]
    for(let wallProps of wallObjects){
        let wall  = <Mag 
        key={wallProps.key}
        width={wallProps.width} 
        height={wallProps.height}
        position={wallProps.position}
        ></Mag>
        walls.push(wall)
    }
    return walls
}

  return (
    <Mesh name="MezzanineSectionStructure" rotation={rotation} position={position} >
        {generateSideWalls(lengthArray,mezzaninesArray, height, gap, position, rotation)}
    </Mesh>
  )
}

export default MezzanineSectionStructure