import { Vector3 } from "@babylonjs/core";
import React from "react";
import { Mesh } from "react-babylonjs";
import SimpleWallSectionSolid from "./SimpleWallSectionSolid";

const RightAwningSolid = ({ rightAwning, length, offset, lengthArray, gap }) => {
    let theta = Math.atan2(rightAwning.length, rightAwning.roofPitch);
    let hypotenouse = rightAwning.length / Math.sin(theta)
    let l = rightAwning.length;
    let p = Math.sqrt( hypotenouse*hypotenouse - l*l)/2

    if (rightAwning.visible)
        return <Mesh name="rightAwning_solid_1" position={new Vector3(-length/2, 0 , offset/2+rightAwning.length)}>
            <SimpleWallSectionSolid
                lengthArray={lengthArray}
                position={new Vector3(0, rightAwning.height/4, 0)}
                height={rightAwning.height}
                gap={gap}
            ></SimpleWallSectionSolid>
                <Mesh name="skillion_right_solid_1" position={new Vector3(0, p + rightAwning.height, -rightAwning.length/2)}>
                    <SimpleWallSectionSolid
                        lengthArray={[length]}
                        height={hypotenouse}
                        rotation={new Vector3(-theta, 0, 0)}
                        gap={gap}
                    ></SimpleWallSectionSolid>
                </Mesh>
        </Mesh>
    else return <React.Fragment></React.Fragment>;
}

export default RightAwningSolid