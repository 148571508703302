import { Vector3 } from '@babylonjs/core'
import React, { useEffect, useRef, useState } from 'react'
import { Mesh, Plane } from 'react-babylonjs';
import Stud from './Stud'

function Wall2({ height, width, direction, gap, position, rotation = new Vector3(0, 0, 0) }) {

    const [studs, setStuds] = useState([])
    useEffect(() => {
        getStuds();
    }, [height, width, direction, gap])

    const getUniqueName = (i, h) => {
        let name = "stud" + h + height + "-" + width + "-" + direction + "-" + gap + "-" + i
        return name;
    }

    const getStuds = () => {
        setStuds([])


        let _studs = []
        let halfWidth = width * 0.5;
        let halfHeight = height * 0.5;

        let stud;
        stud = <Stud
            key={getUniqueName(0, 'v')}
            size={0.1}
            height={height}
            name={getUniqueName(0, 'v')}
            position={new Vector3(0, halfHeight + position.y, 0)}
        ></Stud>

        _studs.push(stud)

        stud = <Stud
            key={getUniqueName(1, 'v')}
            size={0.1}
            height={height}
            name={getUniqueName(1, 'v')}
            position={new Vector3(width, halfHeight + position.y, 0)}
        ></Stud>
        _studs.push(stud)
        let i = 0;
        for (; i <= height; i += gap) {
            let stud = <Stud
                key={getUniqueName(i, 'h')}
                size={0.1}
                height={width * 1.0}
                name={getUniqueName(i, 'h')}
                position={new Vector3(halfWidth, i, 0)}
                rotation={new Vector3(0, 0, Math.PI / 2)}
            >
            </Stud>

            _studs.push(stud)
        }
        stud = <Stud
            key={getUniqueName(i, 'h')}
            size={0.1}
            height={width * 1.0}
            name={getUniqueName(i, 'h')}
            position={new Vector3(halfWidth, height, 0)}
            rotation={new Vector3(0, 0, Math.PI / 2)}
        >
        </Stud>
        _studs.push(stud)
        setStuds(_studs)
        return studs;
    }


    return (
        <Mesh rotation={rotation} position={position} name="wall">
            {studs}
        </Mesh>



    )
}

export default Wall2