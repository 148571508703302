import React from 'react';
import './App.css';
import Tools from './components/Tools';
import Scene from './components/Scene2';

function App() {
  return (
    <div className="App">
      <Tools></Tools>
      <Scene></Scene>
    </div>
  );
}

export default App;
