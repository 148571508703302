import { Vector3 } from "@babylonjs/core";
import React, { useRef } from "react";
import { Engine, Ground, HemisphericLight, Mesh, Scene, Skybox, StandardMaterial, Texture } from "react-babylonjs";
import { useSelector } from "react-redux";
import SimpleWallSection from "../shed/Components/SimpleWallSectionStructure";
import Solid from "../shed/Components/solid/Solid";
import WallSolid from "../shed/Components/solid/WallSolid";
import Structure from "../shed/Components/Structure";
import { RoofTypes } from "../store/constants";

export const rad = (deg) => deg * Math.PI / 180.0;

const ShedScene2 = (props) => {


    const size = 400;
    var lightDistance = 200;
    var lightIntensity = 0.4;
    var lightHeight = 20;

    var height = useSelector((state) => state.config.height);
    var width = useSelector((state) => state.config.width);
    var lengthArray = useSelector((state) => state.config.lengthArray);
    var wallsArray = useSelector((state) => state.config.wallsArray);
    var mezzaninesArray = useSelector((state) => state.config.mezzaninesArray);
    var backWall = useSelector((state) => state.config.backWall);
    var mezzanineHeight = useSelector((state) => state.config.mezzanineHeight);
    var totalLength = lengthArray.reduce((a, b) => a + b, 0)
    var roofPitch = useSelector((state) => state.config.roofPitch);
    var roofType = useSelector((state) => state.config.roofType);


    var leftAwning = useSelector((state) => state.config.leftAwning);
    var rightAwning = useSelector((state) => state.config.rightAwning);
    var view = useSelector((state) => state.config.view);
    var gap = height / 5;
    gap = height / gap;


    let bxref = useRef(null)
    return <div className="scene">
        <Engine antialias adaptToDeviceRatio canvasId="shed-canvas">
            <Scene>
                <arcRotateCamera
                    name="camera1"
                    target={Vector3.Zero()}
                    alpha={0}
                    beta={90 * Math.PI / 180.0}
                    radius={50}
                    upperRadiusLimit={400}
                    lowerRadiusLimit={4}
                    lowerBetaLimit={0 * Math.PI / 180.0}
                    upperBetaLimit={80 * Math.PI / 180.0}
                    allowUpsideDown={false}
                />
                <Skybox name="skybox" rootUrl="/textures/skybox" size={size * 10}></Skybox>
                <HemisphericLight name="light1" intensity={lightIntensity} direction={new Vector3(-lightDistance, 0, lightHeight)}></HemisphericLight>
                <HemisphericLight name="light2" intensity={lightIntensity} direction={new Vector3(lightDistance, 0, lightHeight)}></HemisphericLight>
                <HemisphericLight name="light3" intensity={lightIntensity} direction={new Vector3(0, lightDistance, lightHeight)}></HemisphericLight>
                <HemisphericLight name="light4" intensity={lightIntensity} direction={new Vector3(0, -lightDistance, lightHeight)}></HemisphericLight>

                <Ground name="ground" size={size * 2} width={size * 10} height={size * 10}>
                    <StandardMaterial name="ground-material" roughness={100}>
                        <Texture url="/textures/grass2.jpg" uScale="100" vScale="100"></Texture>
                    </StandardMaterial>
                </Ground>

                {view.structure && <Structure
                    gap={gap}
                    lengthArray={lengthArray}
                    wallsArray={wallsArray}
                    mezzaninesArray={mezzaninesArray}
                    height={height}
                    width={width}
                    roofPitch={roofPitch}
                    roofType={roofType}
                    totalLength={totalLength}
                    leftAwning={leftAwning}
                    rightAwning={rightAwning}
                    backWall={backWall}
                    mezzanineHeight={mezzanineHeight}
                ></Structure>}


                {view.solid &&
                    <Solid
                        gap={gap}
                        lengthArray={lengthArray}
                        height={height}
                        width={width}
                        roofPitch={roofPitch}
                        roofType={roofType}
                        totalLength={totalLength}
                        leftAwning={leftAwning}
                        rightAwning={rightAwning}
                    >
                    </Solid>
                }

            </Scene>
        </Engine>
    </div>
}
export default ShedScene2