// import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux";
import Slider from "react-smooth-range-input";
import { setMezzanineHeight, setMezzaninesArray } from "../../store/config-slice";
import useTool from "./TookHook"

const useMezzanineTool = (tools) => {

    const tool = useTool(tools)

    const mezzaninesArray = useSelector((state) => state.config.mezzaninesArray)

    var mezzanineHeight = useSelector((state) => state.config.mezzanineHeight);
    const dispatch = useDispatch();

    const getMezzanine = () => {
        let checks = []

        for (let i = 0; i < mezzaninesArray.length; i++) {
            let check = <><h5>Bay {i + 1}</h5>
                <hr />
                <div className="form-check">

                    <input className="form-check-input"
                        type="checkbox"
                        key={"wallcheck-123" + i}
                        checked={mezzaninesArray[i]}
                        onChange={e => {
                            let newMezzaninesArray = [...mezzaninesArray]
                            newMezzaninesArray[i] = e.target.checked
                            dispatch(setMezzaninesArray(newMezzaninesArray))
                        }
                        }
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Visible
                    </label>
                </div>
            </>



            checks.push(check);
        }
        return checks;

    };

    const Controls = () => {
        if (tool.isOpen)
            return <div className="tool-controls">
                <div className="controls">
                    <h5>Mezzanine</h5>
                    <hr />

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            All Visible
                        </label>
                    </div>

                    <div className="row">
                        <label htmlFor="">Floor Height(m)</label>
                        <div className="col-9">
                            <div className="form-group">

                                <Slider value={mezzanineHeight}
                                    onChange={(e) => {
                                        dispatch(setMezzanineHeight(e))
                                    }}
                                    min={1} max={30} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <input defaultValue={mezzanineHeight} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    {getMezzanine()}
                </div>
            </div>

        return null;
    }

    const displayControl = () => {
        if (tool.isOpen)
            tool.handler()
        else {
            tools.forEach(t => t.setTool(false))
            tool.handler();
        }

    }

    const Tool = () => <div className="tool-section">
        <div className="tool" data-options="size" onClick={e => { displayControl(); }}>
            <svg style={{ "width": "24px", "height": "24px" }} viewBox="0 0 24 24"
                fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.414">
                <path
                    d="M24 5.932h-4.8v3.614h-4.8v3.613H9.6v3.614H4.8v3.614H0v3.612L4.8 24 24 9.546V5.932zM0 14.716L19.015 0l1.025.75L1.025 15.468z">
                </path>
                <path d="M18.951 0H24v1.076H18.95zM21.475 5.932V1.077h1.43v4.855zM0 20.853v-6.132h1.43v6.132z">
                </path>
            </svg>
            <span>Mezzanine</span>
        </div>
    </div>

    return [Tool, Controls];
}

export default useMezzanineTool