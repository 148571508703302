import { createSlice } from '@reduxjs/toolkit'
import { RoofTypes } from './constants'


const initialState = {
  width: 20,
  height: 20,
  length: 30,
  lengthArray:[],
  wallsArray:[],
  mezzaninesArray:[],
  mezzanineHeight:10,
  backWall:true,
  roofPitch:11,
  roofType: RoofTypes.gable,
  leftAwning: {visible: false, length: 10, height: 10, roofPitch: 5},
  rightAwning: {visible: false, length: 10, height: 10, roofPitch: 5},
  view: { solid: true, structure: true }
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setWidth: (state, action) => {
      state.width = Number(action.payload)
      // state = {...state}
    },
    setHeight: (state, action) => {
      state.height = Number(action.payload)
      // state = {...state}
    },
    setLength: (state, action) => {
      state.length = Number(action.payload)
      // state = {...state}
    },
    setLengthArray: (state, action) =>{
      state.lengthArray = action.payload
      
      let ll = state.lengthArray.length;
      let wl = state.wallsArray.length;
      let ml = state.mezzaninesArray.length

      let diff = ll - wl;
      if(diff < 0) {
        state.wallsArray.splice(ll-1, diff) 
      }
      else if(diff>0){
        for(let i=0; i<diff;i++){
          state.wallsArray.push(false);
        }
      }

      diff = ll - ml;
      if(diff < 0) {
        state.mezzaninesArray.splice(ll-1, diff) 
      }
      else if(diff>0){
        for(let i=0; i<diff;i++){
          state.mezzaninesArray.push(false);
        }
      }


      // state = {...state}
    },
    setWallsArray: (state, action) =>{
      state.wallsArray = action.payload
      // state = {...state}
    },
    setMezzaninesArray: (state, action) =>{
      state.mezzaninesArray = action.payload
      // state = {...state}
    },
    setMezzanineHeight:(state, action)=>{
      state.mezzanineHeight = action.payload
    },
    setRoofPitch: (state, action) =>{
      state.roofPitch = action.payload
      // state = {...state}
    },
    setRoofType: (state, action) => {
      state.roofType = action.payload
      // state = {...state}
    },
    setLeftAwning: (state, action) => {
      state.leftAwning[action.payload.name] = action.payload.value
      // state = {...state}
    },
    setRightAwning: (state, action) => {
      state.rightAwning[action.payload.name] = action.payload.value
      // state = {...state}
    },
    setView: (state, action) => {
      state.view[action.payload.name] = action.payload.value; 
      state = {...state}
    },
    setBackWall:(state, action)=>{
      state.backWall = action.payload
    }

  },
})

// Action creators are generated for each case reducer function
export const { setWidth, 
              setHeight, 
              setLength, 
              setLengthArray, 
              setWallsArray,
              setRoofPitch, 
              setRoofType,
              setLeftAwning,
              setRightAwning,
              setView,
              setBackWall,
              setMezzaninesArray,
              setMezzanineHeight
            } = configSlice.actions

export default configSlice.reducer