import { useState } from "react";

const useTool = (tools: any[]) =>{
    const [isOpen, setTool] = useState(false);
    // tools.forEach(tool => tool.setTool(false))
    const handler = () => {
        setTool(!isOpen)
    }
    tools.push({isOpen, handler, setTool})
    // return [isToolOpen, ToolBoxHandler]
    return {isOpen, handler, setTool}
}

export default useTool