import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setBackWall, setWallsArray } from "../../store/config-slice"
import useTool from "./TookHook"

const useWallsTool = (tools) => {

    const tool = useTool(tools)
    const wallsArray = useSelector((state) => state.config.wallsArray)
    const backWall = useSelector((state) => state.config.backWall)
    const dispatch = useDispatch();
    const getWalls = () => {
        let checks = []

        let check = <div className="form-check">
            <input className="form-check-input"
                type="checkbox"
                checked={backWall}
                onChange={e => {
                    // console.log(e.target.checked)
                    dispatch(setBackWall(e.target.checked))
                }
                }
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
                1
            </label>
        </div>

        checks.push(check);
        for (let i = 0; i < wallsArray.length; i++) {
            let check = <div className="form-check">
                <input className="form-check-input"
                    type="checkbox"
                    key={"wallcheck-123"+i}
                    checked={wallsArray[i]}
                    onChange={e => {
                        console.log(wallsArray)
                        let newWallsArray = [...wallsArray]
                        newWallsArray[i] = e.target.checked
                        // wallsArray[i] = !e.target.checked;
                        dispatch(setWallsArray(newWallsArray))
                    }
                    }
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    {i+2}
                </label>
            </div>
            checks.push(check);
        }

        return checks;
    }
    const Controls = () => {
        if (tool.isOpen)
            return <div className="tool-controls">
                <div className="controls">
                    <h5>Walls</h5>
                    <hr />

                    {getWalls()}
                </div>
            </div>

        return null;
    }

    const displayControl = () => {
        if (tool.isOpen)
            tool.handler()
        else {
            tools.forEach(t => t.setTool(false))
            tool.handler();
        }

    }

    const Tool = () => <div className="tool-section">
        <div className="tool" data-options="size" onClick={e => { displayControl(); }}>
            <svg className="SVGInline-svg icon-svg" style={{ "width": "24px", "height": "24px" }} width="24" height="24"
                viewBox="0 0 24 24">
                <path
                    d="M.639 4.1v17.583h1.67V4.1zM5.469 3.46v18.135h1.674V3.46zM8.973 3.493V21.55h1.672V3.493zM13.78 3.73v17.894h1.663V3.73zM17.002 3.491V21.55h1.67V3.491zM21.719 4.062v17.576h1.642V4.062z">
                </path>
                <path
                    d="M2.169 1.464l-.073.125L.64 4.106l1.496.649L3.17 2.964h1.342l1.334 2h4.225l1.334-2h1.197l1.334 2h4.225l1.334-2h1.197l1.215 1.822 1.453-.725-1.732-2.597h-3.069l-1.334 2h-2.353l-1.334-2h-3.069l-1.334 2H6.782l-1.334-2zM2.169 19.036l-.072.125L.64 21.68l1.496.648 1.035-1.79h1.342l1.334 2h4.224l1.334-2h1.198l1.334 2h4.224l1.334-2h1.197l1.215 1.822 1.453-.725-1.732-2.598H18.56l-1.334 2h-2.354l-1.334-2H10.47l-1.334 2H6.782l-1.334-2z">
                </path>
            </svg>
            <span>Walls</span>
        </div>
    </div>

    return [Tool, Controls];
}

export default useWallsTool