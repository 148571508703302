import { Vector3 } from '@babylonjs/core';
import React from 'react'
import { Mesh } from 'react-babylonjs';
import SimpleWallSectionSolid from './SimpleWallSectionSolid';

export const SkillionLeftSolid = ({ length, width, roofPitch, gap, height }) => {
  let theta = Math.atan2(width, roofPitch);
  let hypotenouse = width / Math.sin(theta)
  return <Mesh name="SkillionLeftSolid" position={new Vector3(0, height + roofPitch/2,0)}>
      <Mesh name="skillion_left_1">
          <SimpleWallSectionSolid
              lengthArray={[length]}
              height={roofPitch}
              gap={gap}
          ></SimpleWallSectionSolid>
      </Mesh>
      <Mesh name="skillion_left_2" position={new Vector3(0, 0, width/2)}
      rotation={new Vector3(-theta, 0, 0)}
      >
          <SimpleWallSectionSolid
              lengthArray={[length]}
              height={hypotenouse}
              gap={gap}
          ></SimpleWallSectionSolid>
      </Mesh>
  </Mesh>
}
export default SkillionLeftSolid