// import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import Slider from "react-smooth-range-input"
import { setLeftAwning, setRightAwning } from "../../store/config-slice"
import useTool from "./TookHook"

const useAwningsTool = (tools) => {

    const tool = useTool(tools)

    const rightAwning = useSelector((state) => state.config.rightAwning)
    const leftAwning = useSelector((state) => state.config.leftAwning)

    const dispatch = useDispatch();
    const Controls = () => {
        if (tool.isOpen)
            return <div className="tool-controls">
                <div className="controls">
                    <h5>Awnings</h5>
                    <hr />

                    <h5>Awnings Right</h5>
                    <hr />
                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox"
                            checked={rightAwning.visible}
                            onChange={e => dispatch(setRightAwning({ name: "visible", value: e.target.checked }))}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Visible
                        </label>
                    </div>
                    {rightAwning.visible ? <div>
                        <div className="form-group">
                            <label htmlFor="">Roof Pitch</label>
                            <Slider value={rightAwning.roofPitch}
                                onChange={(e) => {
                                    dispatch(setRightAwning({ name: "roofPitch", value: e }))
                                }}
                                min={0} max={45} />
                        </div>

                        <div className="row">
                            <label htmlFor="">Length(m)</label>
                            <div className="col-9">
                                <div className="form-group">
                                    <Slider value={rightAwning.length}
                                        onChange={(e) => {
                                            dispatch(setRightAwning({ name: "length", value: e }))
                                        }}
                                        min={0} max={45} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <input type="text"
                                        className="form-control"
                                        defaultValue={rightAwning.length}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <label htmlFor="">Height Offset(m)</label>
                            <div className="col-9">
                                <div className="form-group">
                                    <Slider value={rightAwning.height}
                                        onChange={(e) => {
                                            dispatch(setRightAwning({ name: "height", value: e }))
                                        }}
                                        min={0} max={45} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <input type="text" className="form-control"
                                        defaultValue={rightAwning.height}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> : ""}


                    <h5>Awnings Left</h5>
                    <hr />
                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox"
                            checked={leftAwning.visible}
                            onChange={e => dispatch(setLeftAwning({ name: "visible", value: e.target.checked }))}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Visible
                        </label>
                    </div>
                    {leftAwning.visible ? <div>

                        <div className="form-group">
                            <label htmlFor="">Roof Pitch</label>
                            <Slider value={leftAwning.roofPitch}
                                onChange={(e) => {
                                    dispatch(setLeftAwning({ name: "roofPitch", value: e }))
                                }}
                                min={0} max={45} />
                        </div>

                        <div className="row">
                            <label htmlFor="">Length(m)</label>
                            <div className="col-9">
                                <div className="form-group">
                                    <Slider value={leftAwning.length}
                                        onChange={(e) => {
                                            dispatch(setLeftAwning({ name: "length", value: e }))
                                        }}
                                        min={0} max={45} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <input type="text" className="form-control" defaultValue={leftAwning.length}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <label htmlFor="">Height Offset(m)</label>
                            <div className="col-9">
                                <div className="form-group">
                                    <Slider value={leftAwning.height}
                                        onChange={(e) => {
                                            dispatch(setLeftAwning({ name: "height", value: e }))
                                        }}
                                        min={0} max={45} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <input type="text" className="form-control" defaultValue={leftAwning.height}/>
                                </div>
                            </div>
                        </div>

                    </div> : ""}
                </div>
            </div>

        return null;
    }

    const displayControl = () => {
        if (tool.isOpen)
            tool.handler()
        else {
            tools.forEach(t => t.setTool(false))
            tool.handler();
        }

    }

    const Tool = () => <div className="tool-section">
        <div className="tool" data-options="size" onClick={e => { displayControl(); }}>
            <svg style={{ "width": "24px", "height": "24px" }} width="24" height="24" viewBox="0 0 24 24">
                <path d="M4 2v20h3V5.95L21 9V6z"></path>
                <path d="M16.012 7.646L16 22h3l.012-14.356z"></path>
            </svg>
            <span>Awnings</span>
        </div>


    </div>

    return [Tool, Controls];
}

export default useAwningsTool