import { Color3, Vector3, VertexData } from '@babylonjs/core';
import React, { useRef, useState } from 'react'
import { FiberMesh, Mesh, useBeforeRender, useClick, useHover,  } from 'react-babylonjs'

// function Stud(props) {

//     var positions = [
//         -0.0, 0.0, -0.0,
//         0.5, 0.0, -0.0,
//         -0.0, 1.0, 0.0,
//         0.5, 1.0, 0.0,
//         0.5, 0.0, -0.0,
//         0.5, 1.0, 0.0,
//         0.5, 0.0, 1.0,
//         0.5, 1.0, 1.0,
//         0.0, 0.0, -0.0,
//         -0.5, 0.0, -0.0,
//         0.0, 1.0, 0.0,
//         -0.5, 1.0, 0.0,
//         -0.5, 0.0, -0.0,
//         -0.5, 1.0, 0.0,
//         -0.5, 0.0, 1.0,
//         -0.5, 1.0, 1.0
//     ]
//     var indices = [
//         2, 3, 1,
//         4, 5, 6,
//         5, 8, 6,
//         11, 10, 9,
//         12, 13, 10,
//         16, 13, 14,
//         2, 4, 3,
//         4, 2, 5,
//         5, 7, 8,
//         11, 12, 10,
//         12, 14, 13,
//         16, 15, 13
//     ]
//     var vertexData = new VertexData();
//         vertexData.positions = positions;
//         vertexData.indices = indices;
//   return (
//     <Mesh name="shed" vertexData={vertexData}>

//     </Mesh>
//   )
// }

const Stud = ({name, height,  size, position, rotation}) => {


    // access Babylon scene objects with same React hook as regular DOM elements
    // const boxRef = useRef(null)
  
    return (
      <box
        name={name}
        // ref={boxRef}
        height={height}
        width={size}
        depth={size}
        position={position}
        rotation={rotation}
        
        // scaling={clicked ? BiggerScale : DefaultScale}
      >
        <standardMaterial
          name={`${name}-mat`}
          diffuseColor={Color3.White()}
          specularColor={Color3.White()}
        />
      </box>
    )
  }

export default Stud