import { Vector3 } from "@babylonjs/core"
import React, { useEffect, useState } from "react"
import { Mesh } from "react-babylonjs"
import { rad } from "../../../components/Scene2"
import LeftAwningSolid from "./LeftAwningSolid"
import RightAwningSolid from "./RightAwningSolid"
import RoofSolid from "./RoofSolid"
import SimpleWallSectionSolid from "./SimpleWallSectionSolid"

function Solid({ totalLength, width, lengthArray, height, gap, roofType, leftAwning, rightAwning, roofPitch }) {
  return (
    <React.Fragment>
      <mesh position={new Vector3(-totalLength / 2, height / 2, -width / 2)} name="main_structure_solid">
        <SimpleWallSectionSolid
          lengthArray={lengthArray}
          position={new Vector3(0.0, 0.0, 0.0)}
          height={height}
          gap={gap}
        ></SimpleWallSectionSolid>

        <SimpleWallSectionSolid
          lengthArray={lengthArray}
          position={new Vector3(0.0, 0.0, width / 2)}
          height={height}
          gap={gap}
        ></SimpleWallSectionSolid>

        <Mesh name="backwallsolid" rotation={new Vector3(rad(0), rad(-90), rad(0))}>
          <SimpleWallSectionSolid
            lengthArray={[width]}
            height={height}
            gap={gap}
          ></SimpleWallSectionSolid>
        </Mesh>

        <Mesh name="frontwallsolid" position={new Vector3(totalLength, 0, 0)}
          rotation={new Vector3(rad(0), rad(-90), rad(0))}>
          <SimpleWallSectionSolid
            lengthArray={[width]}
            height={height}
            gap={gap}
          ></SimpleWallSectionSolid>
        </Mesh>
          <RoofSolid roofType={roofType} width={width} height={height/2} totalLength={totalLength} gap={gap} roofPitch={roofPitch}></RoofSolid>  

        
        {/* <SimpleWallSectionStructure
          lengthArray={lengthArray}
          position={new Vector3(0.0, 0.0, 0.0)}
          height={height}
          gap={gap}
        ></SimpleWallSectionStructure> */}
      </mesh>
      <LeftAwningSolid lengthArray={lengthArray} gap={gap} leftAwning={leftAwning} length={totalLength} offset={-width}></LeftAwningSolid>
      
      <RightAwningSolid lengthArray={lengthArray} gap={gap}  rightAwning={rightAwning} length={totalLength} offset={width}></RightAwningSolid>
    </React.Fragment>
  )
}


export default Solid

