import { Vector3 } from '@babylonjs/core';
import React from 'react'
import { Mesh } from 'react-babylonjs';
import Wall2 from './Wall2';

function SimpleWallSection({lengthArray, height, gap, position=new Vector3(0, 0.0, 0), rotation=new Vector3(0,0,0)}) {

const generateSideWalls = () => {
    let walls = [];
    let wallObjects = []
    let xoffset = 0;
    for(let i of lengthArray){
        let newPostion = new Vector3(position.x, position.y, position.z)
        newPostion.x += xoffset;
        let wall = {
            key : xoffset,
            position: newPostion,
            height:height,
            width:i,
            gap:gap,
            showWall:true,
            direction:0
        };
        xoffset += i;
        wallObjects.push(wall)
    }
    xoffset-= lengthArray[lengthArray.length-1]
    for(let wallProps of wallObjects){
        let wall = <Wall2 {...wallProps} ></Wall2>
        walls.push(wall)
    }
    return walls
}

  return (
    <Mesh name="SimpleWallSectionStructure" rotation={rotation} position={position} >
        {generateSideWalls()}
    </Mesh>
  )
}

export default SimpleWallSection