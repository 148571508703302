
import { RoofTypes } from "../../store/constants"
import GrableStructure from "./GrableStructure"
import SkillionLeftStructure from "./SkillionLeftStructure"
import SkillionRightStrucure from "./SkillionRightStrucure"

const RoofStructure = ({roofType, width, height, totalLength, gap, roofPitch}) => {
    if (roofType === RoofTypes.gable) {
        return <GrableStructure
            height={height}
            length={totalLength}
            width={width}
            gap={gap}
            roofPitch={roofPitch}
        />
    }
    else if (roofType === RoofTypes.skillion_right) {
        return <SkillionRightStrucure
            height={height}
            length={totalLength}
            width={width}
            gap={gap}
            roofPitch={roofPitch}
        />
    }
    else if (roofType === RoofTypes.skillion_left) {
        return <SkillionLeftStructure
            height={height}
            length={totalLength}
            width={width}
            gap={gap}
            roofPitch={roofPitch}
        />
    }
}
export default RoofStructure